define('Magento_CustomerCustomAttributes/js/google_maps_loader',['jquery'], function($) {
    var google_maps_loaded_def = null;
    var apiKey = window.googleMapApiKey || false;
    if (!google_maps_loaded_def) {
        google_maps_loaded_def = $.Deferred();
        window.google_maps_loaded = function() {
            google_maps_loaded_def.resolve(google.maps);
        }
        if(apiKey===false || apiKey ===null){
            google_maps_loaded_def.reject();
        }else {
            var url = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=google_maps_loaded&libraries=places,geometry';
            require([url], function () {
            }, function (err) {
                console.error(err);
                google_maps_loaded_def.reject();
            });
        }
    }
    return google_maps_loaded_def.promise();
});
