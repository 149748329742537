define('WolfSellers_Checkout/js/google_maps_loader',['jquery'],function ($) {
    try {
        var google_maps_loaded_def = $.Deferred();
        var apiKey = window.checkoutConfig.api_key || false;
        window.google_maps_loaded = function () {
            google_maps_loaded_def.resolve();
        };
        if (apiKey === false || apiKey === null) {
            google_maps_loaded_def.reject('Google Maps Api Key is not valid.');
        } else {
            var url = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&libraries=places&callback=google_maps_loaded';
            require([url], function () {}, function (err) {
                google_maps_loaded_def.reject(err);
            });
        }
        return google_maps_loaded_def.promise();
    }catch (e) {
        console.error(e);
    }
});
