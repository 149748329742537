require([
    'jquery',
    'slick',
    'domReady!'
], function ($) {
    if( $(".cms-index-index:visible").length == 0 )
    {
        return false;
    }
    $(".bontabs").find("a.tab-title").click(function (e) {
        e.preventDefault();
    });
    $(".bontabs").find("a.tab-title:visible:not(:eq(0))").each(function(){
        $(this).addClass("st-tab-home");
    });
    var options = {
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover:true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    centerMode: true,
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 769,
                settings: {
                    centerMode: true,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 666,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 2
                }
            }
        ]
    };
    $("div.bontabs").find(".product-items").slick(options);
    $(".bontabs").find("a.tab-title").on('click',function(){
        var elem = $(this);
        if(elem.hasClass('st-tab-home')){
            elem.removeClass('st-tab-home');
            setTimeout(function(){
                $("div.bontabs").find(".product-items:visible").slick('refresh');
            },5);
        }

        var _href = elem.attr('href'),
            _content = $(_href).find('div.slick-track');

        if ($(_content).width() == 0) {
            $(_content).css({
                'width' : '100%'
            })

            var _width = 100 / options.slidesToShow;

            $(_content).find('div.slick-active').css({
                'width' : _width + '%'
            })
        }
    });
});

define("Magento_Theme/js/tabs-slick", function(){});

