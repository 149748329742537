require([
'jquery',
'domReady!'
], function ($) {


		$(window).scroll(function(){
		    if ($(window).scrollTop() >= 1) {
		        $('body').addClass('nav-up');
		    }
		    else {
		        $('body').removeClass('nav-up');
		    }
		});


		window.onload = function(){
	        if(window.innerWidth < 768) {
            	$(':input').focus(function () {
                     var center = $(window).height() / 2;
                     var top = $(this).offset().top;
                     if (top > center) {
                         $('html, body').animate({ scrollTop: top - center }, 'fast');            
                     }
                 });
	        }
	    }

	    $('.page-main-description').click(function() {
	        $(this).toggleClass('showcont');
	    });

});


define("Magento_Theme/js/header-sticky", function(){});

