require([
    'jquery',
    'jquery/jquery.cookie',
    'mage/translate',
    'domReady!'
], function ($) {
    var coverage = $.cookie('coverage');
    var cedi = $.cookie('cedi');
    if(parseInt(coverage) == 1 && cedi.trim()) {
        var text1 = $.mage.__('Tu dirección de entrega');
        var text2 = $.mage.__('Cuenta con cobertura');
        $("div.contentubica").html("<div><strong>" + text1 + "</strong><p>" + text2 + "</p></div>");
        $("div.contentubica").css('visibility','visible');
    } else {
        $("div.contentubica").css('visibility','visible');
    }
});

define("WolfSellers_Flow/js/coverage", function(){});

