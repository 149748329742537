define('WolfSellers_Flow/js/modal_coverage',[
    'uiComponent',
    'jquery',
    'ko',
    'Magento_Ui/js/modal/modal',
    'Magento_Customer/js/customer-data',
    'mage/url',
    'Magento_CustomerCustomAttributes/js/google_maps_loader'
], function (Component, $, ko, modal, customerData, urlBuilder, GoogleMapsLoader) {
    'use strict';

     var NOT_SUPPORTED = $.mage.__("Geolocation is not supported in this browser.");
     var NO_COVERAGE =  $.mage.__("Sorry, we don't have coverage in this area.");
     var TIMEOUT_MESSAGE = $.mage.__('We are having problems with the service, please try again later.');
     var YES_COVERAGE = $.mage.__("Yes, we have coverage in that address.");
     var ERROR_MESSAGE = $.mage.__('We are having problems with the service, please try again later.');

    var loaded = false;
    var googleMapsError = false;

    GoogleMapsLoader.done(function () {
        loaded = true;
    }).fail(function () {
        var error = '<div>' + $.mage.__('ERROR: Google maps library failed to load.') + '</div>';
        $('#summa-google-maps-messages').attr('class', 'message error').html(error).show();
    });

    window.gm_authFailure = function (error) {
        googleMapsError = true;
        //this._showMessage('Google Maps error. ' + error, 'error');
    };

    return Component.extend({
        lat: 19.384461,
        lng: -99.266616,
        zone: 1,
        timeout: 30000,
        options: {
            modalButton: 'a.gps',
            modalContent: '#modal-gps-popup',
            modalForm: '#gps-form',
            modalBtnNew: '#gps-btn-new',
            modalBtnCurrent: '#gps-btn-current',
            modalBtnNext: '#next-location'
        },
        elementID: 'summa-google-maps-header',
        map: null,
        marker: null,
        autocomplete: null,
        coverage: false,
        googleMapsErrorLoading: false,
        messages:{
            selector: '#summa-google-maps-messages-header',
            timeout: 5000
        },
        tries: 1,
        gpsOptions: {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 10000
        },
        componentForm: {
            route: { index: 'long_name', value: '' },
            street_number: { index: 'long_name', value: '' },
            sublocality_level_1: { index: 'long_name', value: '' },
            postal_code: { index: 'long_name', value: '' },
            locality: { index: 'long_name', value: '' },
            administrative_area_level_1: { index: 'short_name', value: '' },
            country: { index: 'long_name', value: '' }
        },
        initialize: function () {
            this._super();
            var self = this;
            var modal_coverage_element = $('#modal-coverage');
            modal(this.getModalOptions(), modal_coverage_element);
            $(this.options.modalButton).off("click").on("click", function (e) {
                e.preventDefault();
                self.resetModal();
                self.openModalOverlayModal();
                $('body').addClass('opengps');
            });
            $(this.options.modalBtnNew).off("click").on("click", function (e) {
                e.preventDefault();
                self._nextStep();
            });
            $(this.options.modalBtnCurrent).off("click").on("click",function (e){
                e.preventDefault();
                self._getLocation();
            });
            $(this.options.modalBtnNext).off("click").on("click",function (e){
                e.preventDefault();
                window.location.reload(true);
            });
            var interval = setInterval(function () {
                if (!loaded) {
                    return false;
                }
                clearInterval(interval);
                self._initMap();
                self._initAutocomplete();
            }, 1000);
        },
        _nextStep: function (){
            $("div.gps-step-two").removeClass('no-bon');
            $("div.gps-step-one").addClass('no-bon');
        },
        _updateQuote: function (){
            var _self = this;
            $.ajax({
                url: urlBuilder.build('rest/V1/updateCartLocationPrices'),
                type: 'GET',
                data: {},
                global: true,
                contentType: 'application/json',
                cache: false,
                showLoader: true,
                timeout: self.timeout
            }).always(function (response) {
            });
        },
        resetModal:function (){
            $("div.gps-step-one").removeClass('no-bon');
            $("div.gps-step-two").addClass('no-bon');
            var elem = $("#next-location");
            elem.removeClass('primary').addClass('secondary');
            elem.prop("disabled",true);
        },
        getModalOptions: function() {
            var options = {
                type: 'popup',
                responsive: true,
                innerScroll: false,
                title: false,
                buttons: false,
                closed: function () {
                    $('body').removeClass('opengps');
                }
            };
            return options;
        },
        openModalOverlayModal:function(){
            var modalContainer = $("#modal-coverage");
            modalContainer.removeClass('no-bon');
            modalContainer.modal('openModal');
        },
        _initAutocomplete:function (){
            var self = this;
            var input = document.getElementById('myLocation');
            var autocompleteOptions = {
                types: ['geocode'],
                componentRestrictions: { country: 'mx'}
            };
            input.addEventListener("keydown", function (event){
                if (event.keyCode === 13) {
                    event.preventDefault();
                    return false;
                }
            });
            this.autocomplete = new google.maps.places.Autocomplete(input, autocompleteOptions);
            this.autocomplete.addListener('place_changed',self.setCoordinates.bind(this));
        },
        setCoordinates:function(){
            var place = this.autocomplete.getPlace();
            if (typeof place.geometry === 'undefined') {
                return;
            }
            var lat = place.geometry.location.lat();
            var lng = place.geometry.location.lng();
            this._changePosition(lat, lng, false);
            this._updateAddress(place.address_components);
        },
        _initMap: function () {
            if (googleMapsError) {
                return false;
            }
            var self = this;
            self._showLoader();
            var position = this._getJsonPosition();
            var el = document.getElementById(self.elementID);
            var mapOptions = {
                center: position,
                zoom: 17,
                mapTypeControl: true,
                navigationControlOptions: {
                    style: google.maps.NavigationControlStyle.SMALL
                },
                mapTypeId: google.maps.MapTypeId.ROADMAP
            };
            this.map = new google.maps.Map(el, mapOptions);
            this.marker = new google.maps.Marker({
                map: self.map,
                position: position,
                draggable: true
            });
            var geocoder = new google.maps.Geocoder;
            google.maps.event.addListener(self.marker, 'dragend', function() {
                var position = self.marker.getPosition();
                geocoder.geocode({'latLng': self.marker.getPosition()}, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        if (results[0]) {
                            self._updateAddress(results[0].address_components);
                            self._changePosition(position.lat(),position.lng(), false);
                        }
                    }
                });
            });
            this._hideLoader();
        },
        _updateAddress: function(address_components){
            for (var i = 0; i < address_components.length; i++) {
                var addressType = address_components[i].types[0];
                if(this.componentForm[addressType])
                {
                    this.componentForm[addressType]['value'] = address_components[i][this.componentForm[addressType]['index']];
                }
            }
            this._parseAddress();
        },
        _parseAddress: function (){
            var address_short = '';
            $.each(  this.componentForm , function( key, value ) {
                if(value.value != ''){
                    address_short += value.value + ', ';
                }
            });
            address_short = address_short.slice(0, -2);
            $("#myLocation").val(address_short);
        },
        _showLoader: function () {
            $('body').trigger('processStart');
        },
        _hideLoader: function () {
            $('body').trigger('processStop');
        },
        _getLatitude: function () {
            return parseFloat(this.lat);
        },
        _getLongitude: function () {
            return parseFloat(this.lng);
        },
        _getJsonPosition: function() {
            var latitude = this._getLatitude();
            var longitude = this._getLongitude();
            return {
                lat: latitude,
                lng: longitude,
                setCookie: true
            };
        },
        _getPosition: function() {
            var latitude = this._getLatitude();
            var longitude = this._getLongitude();
            return new google.maps.LatLng(latitude, longitude);
        },
        _changePosition: function (lat, lng, currentLocation) {
            this.lat = lat;
            this.lng = lng;
            this._updateMarker();
            this._checkCoverage(currentLocation);
        },
        _updateMarker: function () {
            var position = this._getPosition();
            this.map.setCenter(position);
            this.marker.setPosition(position);
        },
        _checkCoverage: function (currentLocation) {
            var position = this._getJsonPosition();
            var self = this;
            $.ajax({
                url: urlBuilder.build('/rest/V1/api/coverage'),
                type: 'POST',
                data: JSON.stringify(position),
                global: true,
                contentType: 'application/json',
                cache: false,
                showLoader: true,
                timeout: self.timeout
            }).done(function (response) {
                try {
                    self._updateQuote();
                    var elem = $("#next-location");
                    elem.prop("disabled",true);
                    elem.removeClass("has_coverage");
                    if (!response) {
                        throw TIMEOUT_MESSAGE;
                    }
                    var coverage = JSON.parse(response);
                    if (!coverage.coverage) {
                    	dataLayer.push({
                  		  'event':'PageView',
                  		  'eventCategory':'Cobertura envio',
                  		  'eventAction':'Paso 3',
                  		  'eventLabel': 'Lo sentimos, NO tenemos cobertura en esta ubicacion',
                  		  'direccion': document.getElementById("myLocation").value
                  		});
                        throw NO_COVERAGE;
                    }
                    dataLayer.push({
                  	  'event':'PageView',
                  	  'eventCategory':'Cobertura envio',
                  	  'eventAction':'Paso 3',
                  	  'eventLabel': 'Si tenemos cobertura en esa direccion',
                  	  'direccion': document.getElementById("myLocation").value
                  	});

                    self._showMessage(YES_COVERAGE, 'success');
                    self.zone = coverage.zone_code;
                    customerData.set('masterdata',coverage);
                    elem.removeClass('secondary').addClass('primary');
                    elem.prop("disabled",false);
                    elem.addClass("has_coverage");

                    if (currentLocation) {
                        setTimeout(function() {
                            window.location.reload(true);
                            }, 3000
                        );
                    }
                } catch (e) {
                    self._showMessage(e, 'error');
                }
            }).fail(function (xmlhttprequest, textstatus, message) {
                if (textstatus === 'timeout') {
                    self._showMessage(TIMEOUT_MESSAGE, 'error');
                } else {
                    self._showMessage(ERROR_MESSAGE,'error');
                }
            });
        },
        _showMessage: function (message, message_type) {
            if (message.length === 0) {
                return false;
            }
            var sType = message_type || 'info';
            var messageSelector = "message-" + this.tries;

            if($('#'+messageSelector).length > 0){
                return false;
            }

            var content = '<div id="'+ messageSelector + '">'+ message + '</div>';
            var divMessageParent = {
                id: messageSelector,
                class: 'message ' + sType,
                css: {opacity: 0}
            };
            var newMessageChild = $("<div>", divMessageParent);
            $(this.messages.selector).append(newMessageChild);
            $("#" + messageSelector).html(content)
                .animate({opacity: 1}, function (){ })
                .delay(this.messages.timeout)
                .animate({opacity: 0 },function (){ });

            setTimeout(function () {
                $("#" + messageSelector).remove();
            }, this.messages.timeout + 1000);
        },
        _getLocation: function () {
            var self = this;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    self._changePosition(position.coords.latitude,position.coords.longitude, true);
                }, this._errorGettingPosition.bind(this), this.gpsOptions);
            } else {
                this._showMessage(NOT_SUPPORTED, 'error');
            }
        },
        _errorGettingPosition: function (error) {
            var message = "";
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    message = $.mage.__("User denied the request for Geolocation.");
                    break;
                case error.POSITION_UNAVAILABLE:
                    message = $.mage.__("Location information is unavailable.");
                    break;
                case error.TIMEOUT:
                    message = $.mage.__("The request to get user location is timed out.");
                    break;
                case error.UNKNOWN_ERROR:
                    message = $.mage.__("An unknown error occurred.");
                    break;
                default:
                    message = 'default ' + $.mage.__('We need you to share your location to allow you to add products to your cart.')
            }
            this._showMessage(message, 'error');
        }
    });
});

