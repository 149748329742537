/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

define('Magento_PageBuilder/js/resource/vimeo/vimeo-wrapper',[
    'vimeo'
], function (Player) {
    'use strict';

    window.Vimeo = window.Vimeo || {
        'Player': Player
    };
});

