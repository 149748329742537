require([
    'jquery',
    'domReady!'
], function($) {
   $(".input-text, select, textarea").each(function () {
        var that = this;
        if ($(that).val() != "") {
            $(that).parents(".field").addClass("focused")
        }
        $(that).focus(function () {
            $(that).parents(".field").addClass("focused");
        });
        $(that).focusout(function () {
            if ($(that).val() == "") {
                $(that).parents(".field").removeClass("focused")
            }
        });
    });
});

define("Magento_Theme/js/input-text-fx", function(){});

