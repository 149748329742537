define('Magento_Catalog/js/action/cart-update-widget',[
    'jquery',
    'Magento_Customer/js/customer-data',
    'domReady!'
], function ($, customerData)
{
    'use strict';

    return function (config) {

        var minLimit = 1,
            maxLimit = 99999,
            defaultUrl = config.url,
            etiqueta = 'pz';

        $("input[name='qty']").each(function () {
            $(this).data("val",0);
        });

        $('body').on('click', "button.tocart", function (event) {
            var elem = $(this);
            if(elem.data("sending") == true){ return false; }
            var formParent = elem.closest("div.form-add-to-cart");
            elem.hide();
            formParent.find("input[name='qty']").val(minLimit);
            var addToCart = formParent.find("input[name='addtocart']").val();
            requestUpdateCart(formParent,addToCart,elem,1);
            mostrarContador(formParent,true);
        });

        $('body').on('click', "span.action-less", function (e) {
            var elem = $(this);
            if(elem.data("sending") == true){ return false; }
            var formParent = elem.closest("div.add-to-cart");
            var newValue = parseFloat(formParent.find("input[name='qty']").data("val")) - 1;
            if (newValue >= minLimit) {
                formParent.find("input[name='qty']").val(newValue);
                requestUpdateCart(formParent,defaultUrl,elem,0);
            }
        });

        $('body').on('click', "span.action-more", function (e) {
            var elem = $(this);
            if(elem.data("sending") == true || elem.hasClass('disabled')){ return false; }
            var formParent = elem.closest("div.add-to-cart");
            var newValue = parseFloat(formParent.find("input[name='qty']").data("val")) + 1;
            if (newValue <= maxLimit) {
                formParent.find("input[name='qty']").val(newValue);
                requestUpdateCart(formParent,defaultUrl,elem,0);
            }
        });

        $('body').on('focusout', "input[name='qty']", function () {
            var elem = $(this);
            if(elem.data("sending") == true){ return false; }
            var formParent = elem.closest("div.add-to-cart");
            requestUpdateCart(formParent,defaultUrl,elem,0);
        });

        $('body').on('keypress', "input[name='qty']", function (e) {
            if (String.fromCharCode(e.keyCode).match(/[^0-9]/g)){
                return false;
            }
        });

        $('body').on('keyup', "input[name='qty']", function (event) {
            var elem = $(this);
            if(elem.val() != '' && elem.val() == 0){
                elem.val(elem.data('val'));
                return false;
            }
            var formParent = elem.closest("div.add-to-cart");
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if(keycode == '13'){
                event.preventDefault();
                if(elem.data("sending") == true){ return false; }
                requestUpdateCart(formParent,defaultUrl,elem,0);
            }
        });

        $('body').on('click blur', "p.show-text-qty", function (event) {
            var elem = $(this);
            var formParent = elem.closest("div.add-to-cart");
            mostrarContador(formParent,false);
        });

        function requestUpdateCart(formParent,url,elem,addToCartBtn) {
            if(formParent.closest("form").length) {
                formParent = formParent.closest("form");
            }
            var qty = formParent.find("input[name='qty']");
            if(qty.val() == qty.data('val') || qty.val() ==0 || qty.val() == '')
            {
                formParent.find("p.show-text-qty").text(qty.data('val') + etiqueta);
                formParent.find("input[name='qty']").addClass('custom_attributes');
                formParent.find("p.show-text-qty").removeClass('custom_attributes');
                return false;
            }
            $('[data-block="minicart"]').trigger('contentLoading');
            var formData = {
                product: formParent.find("input[name='product']").val(),
                qty: formParent.find("input[name='qty']").val(),
                uenc: formParent.find("input[name='uenc']").val(),
                form_key: $("input[name='form_key']").val(),
                btn_add: addToCartBtn
            }
            //qty.data('val',parseInt(qty.val()));
            elem.data("sending",true);
            elem.parents("div.product-item-info").find("div.loading-mask").show();
            $.ajax({
                url: defaultUrl,
                data: formData,
                dataType: 'json',
                type: 'post',
                cache: false
            }).success(function (data) {
                if (data.success == true) {
                    var _qty = (typeof data.qty !== typeof undefined && data.qty > 0) ? data.qty : qty.val();
                    qty.data('val', parseInt(_qty));
                    qty.val(_qty);
                    formParent.find("p.show-text-qty").text(qty.val() + etiqueta);
                } else {
                    //formParent.find("span.action-more").addClass('disabled');
                }
            }).fail(function() {
                /* do nothing */
            }).always(function() {
                $('[data-block="minicart"]').trigger('contentUpdated');
                //formParent.find("p.show-text-qty").text(qty.val() + etiqueta);
                formParent.find("input[name='qty']").addClass('custom_attributes');
                formParent.find("p.show-text-qty").removeClass('custom_attributes');
                elem.parents("div.product-item-info").find("div.loading-mask").hide();
                elem.data("sending",false);
            });
        }

        function mostrarContador(formParent,start){
            formParent.find("div.add-to-cart").removeClass('custom_attributes');
            if(start === false){
                formParent.find("p.show-text-qty").addClass('custom_attributes');
                formParent.find("input[name='qty']").removeClass('custom_attributes');
            }
        }
    }
});

