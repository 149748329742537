require([
  'jquery',
  'slick'
  ], function ($) {
    $(document).ready(function () {
      $(".related .product-items").slick({
        dots: false,
        infinite: true,
        speed: 300,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 666,
          settings: {
            slidesToShow: 2
          }
        }
        ]
      });
    });
    $(".btnaction").click(function(){
      $("body").toggleClass("openfilters");
    });
  });


define("Magento_Theme/js/car-related", function(){});

